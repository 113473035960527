import "./MailView.css";
import "./MailViewTiny.css";

import { Editor } from "@tinymce/tinymce-react";

import { useEffect, useRef, useState } from "react";
import { icons } from "../../../common/IconsFunc";

import { calcFileSize } from "../../../common/commonFunc";

import { mail_list } from "../mail_content";

function MailView({ id }) {
  const [isValid, setValid] = useState(false);
  const [isFileView, setFileView] = useState(true);

  const editorRef = useRef(null);

  const apiKey = process.env.REACT_APP_TINY;

  const custom_css = `
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  `;

  const mail = mail_list[id];

  const getReplyBox = () => {
    const con = document.getElementById('tinyEditor_container');

    con.classList.add('getcon');
  }

  const removeReplyBox = () => {
    const con = document.getElementById('tinyEditor_container');

    con.classList.remove('getcon');
  }

  const sendMessage = () => {
    const toast = document.getElementById('message_sent_toast');

    toast.classList.add('toast_load');

    setTimeout(() => {
      toast.innerText = "Message sent";
      toast.classList.add('toast_done');
    }, 1000);

    setTimeout(() => {
      toast.classList.remove('toast_load');
    }, 2000);

    setTimeout(() => {
      toast.innerText = "Message sending ...";
      toast.classList.remove('toast_done');
    }, 2500);
  }

  const MailBody = ({ content }) => {
    const box = useRef(null);

    useEffect(() => {
      box.current.innerHTML = content;
    });

    return <div className="m_b_b" ref={box}></div>;
  };

  useEffect(() => {
    if (!mail_list[id]) {
      window.location = `/`;
    } else {
      setValid(true);
    }
  }, [id]);

  return (
    <div className="MailMain">
      <div className="header">
        <div className="btns add_span">
          <div>
            <icons.LeftArrow />
          </div>
        </div>

        <div className="btns px18">
          <div>
            <icons.Download />
          </div>
        </div>

        <div className="btns">
          <div>
            <icons.Flag />
          </div>
        </div>

        <div className="btns">
          <div>
            <icons.TrashBin />
          </div>
        </div>

        <div className="line"></div>

        <div className="btns">
          <div>
            <icons.MailOpend />
          </div>
        </div>

        <div className="btns">
          <div>
            <icons.Clone />
          </div>
        </div>

        <div className="btns">
          <div>
            <icons.Paste />
          </div>
        </div>

        <div className="btns">
          <div>
            <icons.EllipseCol />
          </div>
        </div>

        <div className="fBlank"></div>

        <div className="btns">
          <div>
            <icons.Share />
          </div>
        </div>

        <div className="btns">
          <div>
            <icons.Print />
          </div>
        </div>

        <div className="btns">
          <div>
            <icons.Left />
          </div>
        </div>

        <div className="btns end">
          <div>
            <icons.Right />
          </div>
        </div>
      </div>

      <div className="mail_box" style={{ padding: "10px" }}>
        {isValid ? (
          <>
            {/* <div className="mail_title">
              {mail.title}
            </div>
            <div className="mail_sender">
              {mail.sender}
              <span>{mail.sender_mail}</span>
            </div> */}
            <div id="m_b_t">
              <svg
                className="m_b_t_s"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
              </svg>
              <div id="m_b_title">{mail.title}</div>
            </div>

            <div className="m_b_s">
              <div className="m_b_s_f">
                <div className="m_b_s_n">{mail.sender}</div>
                <div className="m_b_s_e">{`<${mail.sender_mail}>`}</div>
              </div>
              <div className="m_b_s_r" style={{ marginRight: "15px" }}>
                Block
              </div>
              <div className="m_b_s_r">Report Spam</div>
              {mail.time ? <div className="m_b_s_t">{mail.time}</div> : <></>}
            </div>

            <div id="file">
              {mail.file ? (
                <>
                  <div id="file_header">
                    <div
                      style={{ cursor: "pointer", userSelect: "none" }}
                      onClick={() => setFileView(!isFileView)}
                      className="file_up"
                    >
                      <img
                        src={isFileView ? icons.up : icons.down_bone}
                        alt="up"
                      />
                    </div>
                    <div className="mr_10">
                      첨부
                      <span className="file_bold">
                        {isFileView ? " " + mail.file.length : ""}
                      </span>
                      {isFileView ? "개" : "파일"}
                    </div>
                    <div className="mr_10 file_gray">
                      {calcFileSize(mail.file)}
                    </div>
                    <div className="mr_5 file_blue">모두저장</div>
                    <div className="mr_5 file_lightgray">|</div>
                    {/* <div className='mr_5 file_blue'>이미지로 보기</div> */}
                  </div>

                  {isFileView ? (
                    <div id="file_tail">
                      <div className="file_up">
                        <img src={icons.whiteup} alt="up" />
                      </div>

                      <div id="file_tail_box">
                        {mail.file.map((e, i) => {
                          return (
                            <div
                              key={`file_tail_c_key_${i}`}
                              className="file_tail_c"
                              onClick={null}
                            >
                              <div className="file_first_i mr_15">
                                <img
                                  className="file_i"
                                  src={icons.video}
                                  alt="video_play_icon"
                                />
                              </div>
                              <div className="mr_5">{e[0]}</div>
                              <div className="file_gray file_font_small">
                                {e[1]}
                              </div>
                              <div className="file_blank"></div>
                              <div>
                                <img
                                  className="ml_15 file_i"
                                  src={icons.download}
                                  alt="download_icon"
                                />
                              </div>
                              <div>
                                <img
                                  className="ml_15 file_i"
                                  src={icons.delete_i}
                                  alt="delete_icon"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>

            {mail.body ? <MailBody content={mail.body} /> : <></>}

            <div className="mail_reply">
              <div onClick={getReplyBox} className="btn">
                <icons.Reply />
                <div className="t">Reply</div>
              </div>
              <div onClick={sendMessage} className="btn">
                <icons.Forward />
                <div className="t">Forward</div>
              </div>
            </div>

            <div id="tinyEditor_container">
              <Editor
                id="tinyEditor"
                // inline={true}
                initialValue={null}
                ref={editorRef}
                apiKey={apiKey}
                init={{
                  toolbar:
                    "styles fontfamily fontsizeinput | link image media table | selectall removeformat copy newdocument remove | " +
                    "undo redo | forecolor bold italic underline strikethrough charmap | superscript subscript | align lineheight | tinycomments | " +
                    "checklist numlist bullist indent outdent",

                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "preview",
                    "wordcount",
                    "quickbars",
                  ],

                  toolbar_mode: "wrap",
                  quickbars_insert_toolbar: false,
                  tinycomments_mode: "embedded",
                  menubar: false,

                  // images
                  automatic_uploads: false,

                  // image_title: true,
                  images_upload_url: "postAcceptor.php",

                  file_picker_types: "image",
                  file_picker_callback: (cb, value, meta) => {
                    const input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");

                    input.addEventListener("change", (e) => {
                      const file = e.target.files[0];

                      const reader = new FileReader();
                      reader.addEventListener("load", () => {
                        /*
                      Note: Now we need to register the blob in TinyMCEs image blob
                      registry. In the next release this part hopefully won't be
                      necessary, as we are looking to handle it internally.
                    */
                        const id = "blobid" + new Date().getTime();
                        const blobCache =
                          window.tinymce.activeEditor.editorUpload.blobCache;
                        const base64 = reader.result.split(",")[1];
                        const blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);

                        /* call the callback and populate the Title field with the file name */
                        cb(blobInfo.blobUri(), { title: file.name });
                      });
                      reader.readAsDataURL(file);
                    });

                    input.click();
                  },

                  browser_spellcheck: false,

                  // language: "ko_KR",
                  // min_height: 300,
                  // max_height: 700,

                  content_style: custom_css,
                }}
                // onEditorChange={onEditorChange}
              />

              <div className="btns">
                <div onClick={() => {
                  removeReplyBox();
                  sendMessage();
                }} className="btn send">
                  <icons.Mail />
                  <div className="t">Send</div>
                </div>

                <div onClick={removeReplyBox} className="btn delete">
                  <icons.TrashBin />
                  <div className="t">Delete</div>
                </div>
              </div>
            </div>

            <div id="message_sent_toast">
              <div>Message sending ...</div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default MailView;
