const s = (str) => {
  return `<span class="fBold">${str}</span>`;
}

const findImage = (numb, str, format) => {
  return `/images/resultPage${numb}/${str}.${format ? format : "png"}`;
}

const news_site = {
  news_1 : {
    name: "강한일보",
    icon: `/images/news_logo_circle/circle_1.png`
  },
  news_2 : {
    name: "아시아문화E",
    icon: `/images/news_logo_circle/circle_2.png`
  },
  news_3 : {
    name: "우진뉴스",
    icon: `/images/news_logo_circle/circle_3.png`
  },
  news_4 : {
    name: "해피패밀리뉴스",
    icon: `/images/news_logo_circle/circle_4.png`
  },
  news_5 : {
    name: "삼아일보",
    icon: `/images/news_logo_circle/circle_5.png`
  },
  news_6 : {
    name: "연뉴스",
    icon: `/images/news_logo_circle/circle_6.png`
  },
  news_7 : {
    name: "온더클릭경제",
    icon: `/images/news_logo_circle/circle_7.png`
  },
  news_8 : {
    name: "KBM",
    icon: `/images/news_logo_circle/circle_8.png`
  },
  news_9 : {
    name: "태양일보",
    icon: `/images/news_logo_circle/circle_9.png`
  },
  news_10 : {
    name: "메일뉴데일리",
    icon: `/images/news_logo_circle/circle_10.png`
  },
  news_11 : {
    name: "영앤데일리",
    icon: `/images/news_logo_circle/circle_11.png`
  },
  news_12 : {
    name: "굿라이트닷컴",
    icon: `/images/news_logo_circle/circle_12.png`
  },
  news_13 : {
    name: "조중일보",
    icon: `/images/news_logo_circle/circle_13.png`
  },
}

const news_content = [
  {
    news: news_site.news_5,
    img: "/images/resultPage01/00.png",
    title:"[순간일보] 뮤지컬 '레이지 인더 하우스' 8월 개막…<span class=\"fBold\">차은새</span>·정소연·김혜나",
    body: "자신의 인생을 사랑하며 외로움을 예술로 승화시킨 댄서 '레이지' 역은 <span class=\"fBold\">차은새</span>와 정소연, 김혜나가 캐스팅됐다. '더 댄스'의 진행자로 레이지의 친구이자 연인이었던 '페트릭'역은 윤은빈과 정현욱, 김가호가 이름을 올렸다. 윤은빈과 정현욱은 초연 때 각각 특기인 발레와 바이올린 연주로 관객을 사로잡았다.",
  },
  {
    more: true,
  },
  {
    news: news_site.news_4,
    title:"[해피패밀리뉴스=안선희 기자] <span class=\"fBold\">설우재</span>작가 ‘작가로 먹고사는 일’",
    body: "정삼시립도서관은 3일 14시 도서관 시청각실에서 <span class=\"fBold\">설우재</span> 작가를 초빙하여 “작가로 먹고사는 일”이라는 타이틀로…",
  },
  {
    news: news_site.news_1,
    img: "/images/resultPage01/03.png",
    title:"<span class=\"fBold\">차은새</span> 배우의 안타까운 사망 소식에 공연계가 슬픔에 빠졌다.",
    body: "연예계 등에 따르면 <span class=\"fBold\">차은새</span>는 7일 새벽, 강하구의 오피스텔에 위치한 소속사 사무실에서 사망한 채 인테리어 공사를 위해 방문한 인테리어 회사 직원에 의해 발견되었다고 한다. 빈소는 서울시 강하구 온병원장례식장에 차려졌다. <span class=\"fBold\">차은새</span> 배우 비보에 공연계는 큰 슬픔에 잠겼다. 배우 김은구는 \"사랑하는 동생이자 멋진 배우...",
  },
  {
    news: news_site.news_2,
    img: "/images/resultPage01/05.png",
    title:"베스트셀러 제조기 <span class=\"fBold\">설우재</span> 작가 신작 출간",
    body: "“마음을 온전히 느끼고 누리는 삶에 대하여” 베스트셀러 제조기 <span class=\"fBold\">설우재</span> 작가의 신작 소설 <핫샷>이 출간됐다. 150만 독자를 사로잡은 <span class=\"fBold\">설우재</span> 작가는 이번엔 ‘추리의 신’이 되기를 시도한다. 일상을 비집고 들어가 포착한 순간에서 느끼는 공포의 실체를…",
  },
  {
    news: news_site.news_6,
    img: "/images/resultPage01/02.png",
    title:"<span class=\"fBold\">차은새</span>, '순백의 드레스에 걸맞는 미모...자체발광 천사같은 미모' ",
    body: "[연뉴스=김현지 기자] 연예대상에 배우 <span class=\"fBold\">차은새</span>씨가 초대되어 시상식에 올랐다. 배우 차은씨가 순백의 드레스를 착장하고 나와 주목을 이끌고 있다. 하얀드레스와 걸맞는 미모로 팬들에게 미소를 선사했다.",
  },
  {
    news: news_site.news_8,
    img: "/images/resultPage01/06.png",
    title:"[작가 인터뷰] <span class=\"fBold\">설우재</span> '핫샷' 작가",
    body: "“시대가 변해도 인간의 본성은 변하지 않아요. 인간 본성의 근원을 파헤치거나 범인을 추적해가는 추리 소설 본연의 목적에 충실한 작품들을 쓰고 싶다고 생각했죠.” 추리 소설가 <span class=\"fBold\">설우재</span> 작가는 <미스트 게임>으로 한국 추리 소설계에 등장했다. <미스트 게임>으로 24회 대한민국추리문학상 신인상을 수상, <푸른 살인자>로 대상을 받은 그는 학생 때 낸 단편집으로 주목을 받았다. “추리 소설은 한 호흡에 읽을 때 흡입력이 가장 강하고 인물의 성격이 잘 드러난다고 생각합니다.”",
  },
  {
    news: news_site.news_7,
    img: "/images/resultPage01/03.png",
    title:"[온더클릭경제=한성현 기자]KJ제약 릴렉스엔, 배우 ‘<span class=\"fBold\">차은새</span>’ 브랜드 모델 발탁",
    body: "KJ제약의 음료 브랜드 ‘릴렉스엔’이 브랜드 뮤즈로 배우 ‘<span class=\"fBold\">차은새</span>’를 선정했다. 릴렉스엔은 10일 올해 국내 시장에서 자사 브랜드가 추구하는 편안함을 가장 잘 보여줄 수 있는 모델로 <span class=\"fBold\">차은새</span>를 발탁했다고 밝혔다. ",
  },
  {
    news: news_site.news_4,
    title:"<핫샷> <span class=\"fBold\">설우재</span> \"글과 삶은 분리될 수 없다.\"",
    body: "베스트셀러 '미스트 게임', '핫샷' 등으로 사랑받고 있는 <span class=\"fBold\">설우재</span> 작가가 교당서점의 독자들을 찾아와 직접 강연하는 시간을 가졌다. 글이란 무엇인가. 삶이란 무엇인가….",
  }
];

const news_view = [

];

const news_content_55 = [
  {
    news: news_site.news_3,
    img: findImage(55,1,"jpg"),
    title: `‘충격’ ${s("봉토 화학공장")} 폭발${s("화재")} 14명 사상`,
    body: `⊙앵커: 무언시 ${s("화학공장")} 폭발${s("화재")}로 숨지거나 실종된 사람이 밤새 12명으로 늘어났습니다. 먼저 사고 내용과 피해상황을 최오성 기자가 자세히 보도합니다. ⊙기자: 불이 난 ${s("공장")} 위로 시커먼 연기가 뿜어져 나옵니다. `
  },
  {
    news: news_site.news_5,
    img: findImage(55,2,"jpg"),
    title: `봉토 ${s("화학공장")} 폭발로 12명이 죽고 2명 중상`,
    body: `무언시 동구에 위치한 ${s("봉토화학공장")}에서 12명이 숨지고 2명이 중상을 입는 폭발사고가 발생했다. 24일 오후 무언시 동구 무언공단내에서 화학제품을 생산하는 ${s("봉토화학")}의 에탄올 저장탱크가 폭발해 회사 근로자들 대부분이 대피할 시간 없이 그대로 피해를 입었다.`
  },
  {
    news: news_site.news_1,
    img: findImage(55,3,"jpg"),
    title: `무언 ${s("화학공장 화재")}사고 사망 노동자 딸 “책임 회피 대표와 공장장 처벌해달라”`,
    body: `지난달 무언시 동구의 한 화학제품 생산공장에서 일어난 ${s("화재")}사고로 숨진 40대 노동자의 딸이 공장장를 처벌해 달라며 청원을 제기했다.`
  },
];

const news_content_60 = [
  {
    news: news_site.news_9,
    img: findImage(60,1),
    title: `<멱살 한번 잡힙시다> 폐지 논란.`,
    body: `[태양일보/최정우 기자] 차은새 살인사건으로 도마 위에 올랐던 <멱살 한번 잡힙시다>의 진행자 ${s("서정원")} 기자에 대한 악플과 억측이 연일 도를 넘고 있다. 시비를 가려 법의 심판을 받게 해주겠다던 프로그램에 대한 신빙성과 정체성 또한 흔들리고 있다. 또한 언론이 붉게 물들며 폐지를 가리키고 있는데, 방송이 지속될 수 있을 것인가?`
  },
  {
    news: news_site.news_10,
    title: `${s("서정원")}의 <멱살 한번 잡힙시다> 이대로 표류? 아니면 다시 순항?`,
    body: `[${news_site.news_10.name}/오인화 기자] 보도국의 기자는 사실을 밝히기 위해서는 목숨과도 바꿀 용기와 책임을 갖고 있어야 한다고 했다. 그런 기자가 현재 살인사건에 휘말렸고 그녀가 진행하는 <멱살 한번 잡힙시다> 역시 폭풍 속을 항해하고 있다. 승승장구하던 프로가 휘청이며 계속해서 시청률이 답보상태에 머무르고 있다. 다시 순항하기 위해서라도, 방송사는 수수방관 식으로 지켜만 봐서는 아니 될 것이라는 의견이 빗발치고 있다.`
  },
  {
    news: news_site.news_11,
    img: findImage(60,3),
    title: `<멱살 한번 잡힙시다> 시청률 폭삭, ${s("서정원")} 이미지도 폭삭?`,
    body: `KBM 금요일 탐사기획 프로그램 ‘멱살 한번 잡힙시다’ 가 어젯밤 방송되었다. 전회 10프로에서 반 깍인 5프로 로 충격적인 시청률이 나와 언론이 뜨거워지고 있다. 이 사유로 “진행자인 ${s("서정원")}기자가 살인사건에 휘말리면서 신뢰성을 잃었다.”며 언론 사이에서 시청률이 저조해진 이유에 대해 추측하고 있다. ‘멱살 한번 잡힙시다’ 방송사인 KBM은 이를 어떻게 받아들일 것인가에 대해 집중되어 진다.`
  },
  {
    news: news_site.news_12,
    img: findImage(60,4),
    title: `${s("서정원")}의 <멱살 한번 잡힙시다>, 이대로 멱살 잡히고 끝나나?`,
    body: `[${news_site.news_12.name} 윤명희 기자] <멱살 한번 잡힙시다>는 첫 회부터 한국 탐사 프로그램에 돌풍을 불고 왔다. 사실을 밝히기 위해선 세상 그 어떤 것 과도 거래를 하지 않겠다고 공언했던 프로가 아니었는가. 그랬던 프로가 현재 현저히 시청률이 떨어지고 있으며 반등의 기회를 쉽사리 잡을 것으로는 보이지 않는다. 프로의 시선에서 프로그램을 살릴 수 있는, 그야말로 각고의 노력으로 다시 반등할 <멱살 한번 잡힙시다>를 기대해 본다.`
  },
  {
    news: news_site.news_13,
    title: `${s("서정원")}, ‘한벌 1200만원’ 입 떡벌어진 고가 의상 논란의 진실`,
    body: `[${news_site.news_13.name}/지연성 기자] ${s("서정원")} 기자가 멱살 한번 잡힙시다 에 입고 나와 화제가 된 고가 의상 논란과 관련해 해명했다. 서 기자는 본지와의 인터뷰에서 “최근 멱살 한번 잡힙시다 녹화 일정이 바뀌어서 협찬 의상을 입지 못하게 된 일이 있었다. 아무리 찾아봐도 마땅한 옷이 없더라. 그래서 녹화를 앞두고 두세 시간 먼저 나가 방송국 근처 직접 옷가게를 찾아다녔다.”며 말문을 열었다.`
  },
];

const news_content_61 = [
  {
    news: news_site.news_1,
    img: findImage(61,1),
    title: `모형택, "최남욱 의원이 보좌관 성추행했다." 폭로`,
    body: `[강한일보/김윤상 기자] 최남욱 의원이 보좌관 A씨를 강제추행하고 이로 인한 외상 후 스트레스 장애 등 상해를 입게 한 혐의를 받고있다. 올해 6월 A씨가 새로통합당 인권센터에 최 의원을 신고한 직후 A씨에게 인사상 불이익을 준 사실을 폭로한 것은 모형택 의원이다.`
  },
  {
    news: news_site.news_3,
    title: `최남욱, 보좌관 성추행…명예훼손·직권남용 혐의도 적용될 수 있어, 前검사 출신 모형택, 인권센터 결정지지 발언`,
    body: `새로통합당의 전종윤 의원이 법무부로부터 받은 최 의원에 대한 검찰 공소장에 따르면 서울북부지검은 이달 말, 최 의원을 불구속 기소하면서 강제추행치상, 명예훼손, 직권남용 혐의를 적용했다. 경찰이 검찰로 사건을 넘길 땐 최 의원이 소속 의원실 보좌관 A씨를 올해 6월 강제추행해 상해를 입힌 혐의만 있었다.`
  },
  {
    news: news_site.news_5,
    img: findImage(61,3),
    title: `자신의 계파 수장을 고발한 모형택`,
    body: `[삼아일보/서희준 기자] 보좌관을 성추행한 혐의를 받고있는 최남욱 의원이 피해자에게 보상을 하겠다고 합의를 시도했다는 정황을 발견한 모형택 의원은 이와 같은 사실을 추가 고발했다. 모 의원은 최 의원이 합의가 무산되자 되려 주변에 ‘피해자가 나에게 금전을 갈취하려고 일을 꾸민 것’이라고 주장하고 다닌 것으로 밝혀졌다.`
  },
  {
    news: news_site.news_13,
    title: `모형택 VS 최남욱, 정치 동지에서 적으로`,
    body: `[조중일보/박세나 기자] 최남욱 의원이 모형택 의원을 싸움의 대상으로 선포했다. 최 의원은 “이 싸움은 정치를 이대로 둘 수 없다는 나의 의지의 표명이며, 그간 모 의원이 벌인 정치공작을 밝혀내는 우리 둘의 한판 승부”라며 국가와 국민을 위해 깨끗한 정치를 하는 정치인을 걸러내는 거름망 역할을 자신이 하겠다고 선언했다.`
  },
  {
    news: news_site.news_8,
    img: findImage(61,5),
    title: `모형택·최남욱 갈등 속 전격 회동…서로 갈길 확인하고 갈라섰다`,
    body: `[KBM 정치부/박세나 기자] ‘보좌관 성추행 사건’으로 인해 적대관계로 돌아선 모형택 의원과 최남욱 의원이 오늘 오전 서울시청 인근 한식당에서 전격 회동했지만, 입장 차이를 좁히지 못했다. ‘공식 사과를 받지 못한다면 이대로 다른 길을 갈 수 밖에 없다’ 예고한 상황에서 성사된 만남이었지만 끝내 갈등을 봉합하지 못했다.`
  },
  {
    news: news_site.news_9,
    img: findImage(61,6),
    title: `최남욱, 모형택 겨냥 “큰 희생 치를 것”`,
    body: `최남욱은 여의도, 모형택은 종로, ‘새로통합인사회’ 각각 개최 최남욱 전 새로통합당 대표가 모형택 의원과 모 의원을 지지하는 세력을 두고 “큰 희생을 치를 것”이라는 발언을 했다. 최 전 대표는 이날 개최한 인사회에서 “우리가 국민께 더 깨끗한 정치를 보여드려야 한다”며 “모함에 빠져 진창에 빠졌지만, 진실은 언제고 꼭 밝혀지는 법”이라고 했다.`
  },
];


const news_content_65 = [
  {
    news: news_site.news_1,
    // img: findImage(61, 1),
    title: `전문의 평균 나이, 50대 진입…산부인과 54세 넘어`,
    body: `[세종=강한일보] 김아선 기자 = 인구 고령화로 인해 국내 전문의들의 평균연령이 10년 사이 3.6세 높아져 50대에 진입한 것으로 나타났다. 특히 산부인과, 예방의학과, 비뇨의학과 등 전문의의 평균 연령은 50세를 넘어섰다. 8일 국회 보건복지위원회 김재욱 한국당 의원이 건강보험심사평가원으로부터 제출받은 진료과목별 의사 수 현황을 보면, 지난 7월 기준 전문의 수는 14만8250명이고 평균 연령이 50.1세인 것으로 나타났다. 이는 2014년과 비교하면 전문의 수는 2만7323명(22.6%), 평균연령은 3.6세 상승했다.`
  },
  {
    news: news_site.news_3,
    // img: findImage(61, 1),
    title: `"산부인과 따라온 남편들, 산모에 자리 양보하라" [생각말하기]`,
    body: `[우진뉴스] 아내를 따라 산부인과에 온 남편들을 향해 산모에게 자리를 양보하라고 주장한 누리꾼의 글을 둘러싼 여러 의견이 나오고 있다. 최근 누리꾼 A 씨는 사회관계망서비스(SNS)에 "산부인과에 아내 따라서 오는 남편들아, 앉아 있을 때 간간이 주변 좀 돌아보고 자리가 없어 서 있는 산모들 보이면 좀 빨리빨리 일어나라"라고 적었다. 보호자로 온 남성들이 서 있는 산모들한테 자리를 양보해야 한다는 주장이다. 다만 이 같은 A 씨의 주장에 일각에서는 비난이 쏟아졌다.`,
  },
  {
    news: news_site.news_5,
    // img: findImage(61, 1),
    title: `서울창주병원 산부인과 로봇수술 5000례 돌파`,
    body: `로봇수술기 5대 보유…자궁근종 및 선근증 수술도 국내 최다 기록 서울창주병원 산부인과가 로봇수술 5000례를 달성했다고 7일 밝혔다. 자궁을 보존하는 로봇 근종 및 선근증 수술은 국내 최다 기록을 세웠다. 서울창주병원 산부인과는 7일 본관 3층 산부인과 외래 및 5층 수술실 앞에서 산부인과 로봇수술 5000례 돌파를 기념하는 행사를 개최했다. 서울창주병원 산부인과는 2009년 2세대 로봇수술기 ‘창 S’를 도입한 이후 2017년에 국내 최초로 로봇수술기를 이용한 자궁근종절제술 500건을 돌파했다.`,
  },
  {
    news: news_site.news_7,
    // img: findImage(61, 1),
    title: `조인산부인과, ‘개원 9주년’ 기념행사 열어`,
    body: `[온더클릭경제=조성우 기자] 올해 개원 9주년을 맞이한 조인산부인과가 지난 7일 개원 기념 행사를 개최했다. 기념행사에서 조인제 대표원장은 “자궁근종, 난소낭종과 같은 가벼운 부인과 질환에도 자궁과 난소의 적출을 당연시하던 시기에 ‘보존치료’를 모토로 개원하여 여성의 상징과 같은 자궁·난소를 지키기 위해 최선을 다해왔다”며 “변화와 혁신을 거듭하며 앞만 보고 달려온 조인산부인과는 이제 부인과 질환에 대한 수술과 시술이 모두 가능한, 이른바 자궁·난소 하이브리드 치료 특화 병원으로 입지를 다지고 있고, 지난 9년간 우리 병원을 믿고 찾아 주신 환자들께 감사드립니다”며 9주년의 소감을 밝혔다.`,
  },
  {
    news: news_site.news_9,
    // img: findImage(61, 1),
    title: `"산부인과 따라온 남편들, 다른 산모에 자리 양보하라" 누리꾼 주장 '시끌'`,
    body: `(서울=태양일보) 박정윤 기자 = 아내를 따라 산부인과에 온 남편들을 향해 산모에게 자리를 양보하라고 주장한 누리꾼의 글이 갑론을박을 불러왔다. 최근 누리꾼 A 씨는 SNS에 "산부인과에 아내 따라서 오는 남편들아, 앉아 있을 때 간간이 주변 좀 돌아보고 자리가 없어 서 있는 산모들 보이면 좀 빨리빨리 일어나라"라고 적었다. 보호자로 온 남성들이 서 있는 산모들한테 자리를 양보해야 한다는 것이다. 이 같은 A 씨의 주장에 일각에서는 비난이 쏟아졌다.`,
  },
];

const result_images = [
  {
    img: findImage(55,2,"jpg"),
    body: "봉토 화학공장 폭발로 12명이 죽고 2명 중상",
  },
  {
    img: findImage(55,1,"jpg"),
    body: "‘충격’ 봉토 화학공장 폭발화재 14명 사상",
  },
  {
    img: findImage(55,3,"jpg"),
    body: "무언 화학공장 화재사고 사망 노동자 딸 ",
  },
  {
    img: findImage(55,4,"jpg"),
    body: "봉토 화학 공장 화재로 인한 피해 심각",
  },
  {
    img: findImage(55,5,"jpg"),
    body: "봉토 화학 공장 폭발 화재로 인명 피해 ‘심각’",
  },
  {
    img: findImage(55,7,"jpg"),
    body: "무언시 봉토화학 공장폭발",
  },
  {
    img: findImage(55,6,"jpg"),
    body: "무언 봉토 화학 공장 폭발",
  },
  {
    img: findImage(55,8,"jpg"),
    body: "봉토 화학 공장 폭발 화재로 인해 일대 전소",
  },
  {
    img: findImage(55,11),
    body: "[단독] 무언시 공장 화재 피의자 아내 무언지검 앞 피켓시위",
    func: () => {
      window.location = "https://sangyeong-news-article03-ac20091229u34u29acd394.vercel.app/";
    }
  },
  {
    img: findImage(55,9,"jpg"),
    body: "무언시 봉토 화재사고 발생",
  },
  {
    img: findImage(55,10,"jpg"),
    body: "[뉴스] 봉토 화학 공장 화재",
  },
];

export {
  news_site,
  news_view,
  news_content,
  news_content_55,
  news_content_60,
  news_content_61,
  news_content_65,
  result_images
};