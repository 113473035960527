import { useEffect, useState } from "react";
import "./Mail.css";
import MailHeader from "./MailHeader/MailHeader";
import MailMain from "./MailMain/MailMain";
import MailSidebar from "./MailSidebar/MailSidebar";
import { useParams } from "react-router-dom";
import MailView from "./MailMain/MailView";

function Mail() {
  const [inbox, setInbox] = useState(12);
  const [isView, setView] = useState(false);

  const { mailId } = useParams();

  useEffect(() => {
    if (mailId) {
      setView(true);
      setInbox(null);
    } else {
      setView(false);
      setInbox(12);
    }
  }, [mailId]);

  return (
    <div className="Mail">
      <MailHeader />

      <div className="CommonContainer">
        <MailSidebar inbox={inbox} />
        {isView ? <MailView id={mailId} /> : <MailMain />}
      </div>
    </div>
  );
}

export default Mail;
